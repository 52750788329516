.Home .lander {
    padding: 20px 0;
    text-align: center;
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }

  .twitterSettings {
  
    width: 100%;
  }

  .cards{
    padding-top: 10px;
  }

 
  .row.display-flex {
    display: flex;
    flex-wrap: wrap;
  }
  .row.display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }
  
   /* not requied only for demo * */
  .row [class*='col-'] {
    
    background-clip: content-box;
  }
  .panel {
      height: 100%;
  }
  
  .labelleft{
    text-align: left !important
  }