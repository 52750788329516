.App {
  
}

.color-nav {
  background-color: rgba(129,180,121,255)
}
.font-color {
  color: rgb(255, 255, 255) !important
}
.navbar-brand > img {
  object-fit: cover;
}

.logoImage{
  object-fit: cover;
}

.navbar-hamburger {
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255,255,255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
 
}